const getDocsFromSheet = (sheet) => {
  const res = [];

  sheet.eachRow((row, i) => {
    if (i >= 2) {
      res.push({
        file_id: row.getCell('A').value,
      });
    }
  });

  return res;
};

export default getDocsFromSheet;
