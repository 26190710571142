import React, { useState, useMemo } from 'react';

import { Dialog } from 'components/UIKit';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { NotificationManager as NM } from 'react-notifications';

import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import {
  LinearProgress,
} from '@mui/material';

import makeClassificationTestBenchRequests from './utils/makeClassificationTestBenchRequests';

function getCurrentDateInYYYYMMDD() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

const EvidenceClassificationTestBenchDialog = ({ requestsData: { docs, configs, workbook }, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [maxParallelRequests, setMaxParallelRequests] = useState('10');

  const requests = useMemo(() => configs.map(({ id, ...config }) => docs.map((doc) => ({
    doc,
    config,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }))), []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const sheet = workbook.getWorksheet(1);

    const results = await makeClassificationTestBenchRequests(requests, Number(maxParallelRequests));

    results.forEach((resultItems, index) => {
      sheet.getColumn(3 + index).width = 30;

      let successCount = 0;

      resultItems.forEach((suggestedType, resultItemIndex) => {
        const row = sheet.getRow(2 + resultItemIndex);
        const valueCell = row.getCell(3 + index * 2);
        const statusCell = row.getCell(3 + index * 2 + 1);

        valueCell.font = { bold: false };
        statusCell.font = { bold: false };

        if (suggestedType === null) {
          valueCell.value = 'ERROR';
        } else {
          valueCell.value = suggestedType;

          const isSuccess = suggestedType === row.getCell('B').value;

          statusCell.value = isSuccess ? 'Success' : 'Failure';

          statusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: isSuccess ? 'D9EAD3' : 'F5CCCC' },
          };

          if (isSuccess) {
            successCount += 1;
          }
        }
      });

      const successRate = ((successCount / resultItems.length) * 100).toFixed(2);
      sheet.getRow(1).getCell(3 + index * 2 + 1).value = `${successRate}%`;
    });

    try {
      // eslint-disable-next-line no-param-reassign
      workbook.views[0] = {
        ...workbook.views[0],
        activeTab: 0,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    const modifiedBuffer = await workbook.xlsx.writeBuffer();
    // eslint-disable-next-line max-len
    const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = `Classification result ${getCurrentDateInYYYYMMDD()}.xlsx`;
    downloadLink.click();

    NM.success('Success');

    setIsLoading(false);

    onClose();
  };

  const formValid = maxParallelRequests;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {`Classification requests (configs: ${configs.length}, docs: ${docs.length})`}
      </DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}
        <TextField
          sx={{ display: 'flex', mb: 2 }}
          label="Max parallel requests"
          type="number"
          variant="standard"
          value={maxParallelRequests}
          onChange={(e) => setMaxParallelRequests(e.target.value)}
        />
        <JsonView data={requests} style={defaultStyles} shouldInitiallyExpand={(level) => level < 2} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button variant="contained" disabled={isLoading || !formValid} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EvidenceClassificationTestBenchDialog;
